import React from 'react'
import { Route } from 'react-router'
import Page from '../page';
import SingleTeamMemberScreen from '../../screens/single-team-member-screen';
import SingleArticleScreen from '../../screens/single-article-screen';
import SingleServiceScreen from '../../screens/single-service-screen';

const getPagePath = (page, asLink = false) => {
    let path = `/${page.post_name || 'unkown'}`;
    if (path === '/home') {
        //Add the root to any path called 'home'
        return asLink ? '/' : '/(|home)';
    } else {
        return `${page.parent_slug ? `/${page.parent_slug}${path}` : path}`;
    }
}

const getPageRoutes = website => {
    const { pages, contact, header, footer } = website;
    let pageRoutes = [];
    pages.forEach((page, index) => {
        const path = getPagePath(page);
        pageRoutes.push(
            <Route key={index} path={path} exact render={(routerProps) =>
                <Page {...routerProps} {...page} header={header} footer={footer} contact={contact} />
            } />
        );
    });
    return pageRoutes;
}

const Website = ({ website }) => {
    let { header, footer, contact } = website;
    return (
        <>
            {getPageRoutes(website)}
            <Route key={'team-member-screen'} path='/team-member/:slug' exact render={props => <SingleTeamMemberScreen {...props} header={header} footer={footer} contact={contact} />} />
            <Route key={'single-project-screen'} path='/project/:slug' exact render={props => <SingleArticleScreen {...props} header={header} footer={footer} contact={contact} />} />
            <Route key={'single-publication-screen'} path='/publication/:slug' exact render={props => <SingleArticleScreen {...props} header={header} footer={footer} contact={contact} />} />
            <Route key={'single-news-screen'} path='/news/:slug' exact render={props => <SingleArticleScreen {...props} header={header} footer={footer} contact={contact} />} />
            <Route key={'single-service-screen'} path='/service/:slug' exact render={props => <SingleServiceScreen {...props} header={header} footer={footer} contact={contact} />} />
            <Route key={'single-service-screen-child'} path='/service/:parent/:slug' exact render={props => <SingleServiceScreen {...props} header={header} footer={footer} contact={contact} />} />
        </>
    )
}
export default Website;