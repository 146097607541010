import React from 'react';
import { ThemeProvider } from 'styled-components';

const Theme = ({ children }) => {
    const theme = {
        colors: {
            defaultPrimary : "#000000", // black-fox
            defaultSecondary : "#ffffff", // white
            primary : "#23c9ee", // lightblue 
            secondary : "#3c3c46", // dark fox
            tertiary : "#6e6e6e", // dark grey, 
            transparency: "rgba(60, 60, 70, 0.85)" // dark fox transparent
        },
        fonts: {
            paragraphFont : "Barlow", 
            headingFont : "Barlow",
            italicFont : "Barlow",
        },
        fontWeight: {
            thin: "100",
            extraLight: "200",
            light: "300",
            regular: "400",
            medium: "500",
            semiBold: "600",
            bold: "700",
        },
        fontSizes: {
            extraSmall: "15px",
            small: "28px",
            medium: "50px",
            large: "60px",
            extraLarge: "90px",
            paragraph: "18px"
        },
        spacing: {
            inset: "15px",
            doubleInset: "30px",
            tripleInset: "60px", 
            quarterlyInset: "90px",
            megaInset: "120px",
            none: "0px",
        },
        textAlign: {
            center: "center",
            left: "left",
            right: "right",
            justify: "justify"
        },
        direction: {
            column: "column",
            row: "row",
            columnReverse: "column-reverse",
            rowReverse: "row-reverse"
        },
        alignItems: {
            start: "flex-start",
            center: "center",
            end: "flex-end",
            spaceBetween: "space-between",
            strech: "strech",
            baseLine: "baseline"
        },
        justifyContent: {
            start: "flex-start",
            center: "center",
            end: "flex-end",
            spaceBetween: "space-between",
            spaceAround: "space-around",
            spaceEvenly: "space-evenly"
        },
        position: {
            relative: "relative",
            fixed: "fixed",
            sticky: "sticky",
            absolute: "absolute"
        },
        transition: {
            linear: "all 0.3s linear",
        },
    }
    return (
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    )
}
export default Theme;