import React from "react"
import styled from "styled-components"
import { Container, Row, Column } from '../../components/base/grid';
import ServiceCard from "./service-card"
import { device } from '../../components/base/mediaquery';

const Main = styled(Container).attrs({
	as: 'main'
})`
	height: calc(100vh - 40px);
`;

const StyledContainer = styled(Container)`
	flex-direction: ${props => props.theme.direction[props.direction]};
	height: calc(100vh - 40px);
	background-image: url('${p => p.image}');
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;

	@media(max-width:960px) {
		height:initial;
	}
	@media(max-width:812px) {
		height:initial;
	}

	@media ${device.mobileL} {
		height:initial;
	}
`;

const StyledRow = styled(Row)`
	display:flex;
	flex-direction:${props => props.theme.direction.row};
	height: calc(100vh - 40px);
	align-items:${props => props.theme.alignItems.end};

	@media(max-width:812px) {
		height:initial;
	}
	@media(max-width:480px) {
		height:100%;
	}
`;

const TitleContainer = styled.div`
	@media (max-width: 900px) {
		align-self: center;
		margin: 100px 0 0 0;
	}
`

const Subtitle = styled.h3`
	background: rgba(23, 183, 222, 0.8); /* hex #17b7de */
	color: #272929;
	padding: 8px 40px;
	border-radius: 4px;
	font-size: 24px;
	display:table;
	text-align: center;
	margin: 4px auto;
	font-weight: 400;

	@media (max-width: 768px) {
		height: initial;
	}
	@media (max-width: 500px) {
		font-size: 20px;
	}
`
const Title = styled.h2`
  background: rgba(23, 183, 222, 0.8); /* hex #17b7de */
  color: #FFF;
  font-size: 56px;
  padding: 8px 40px;
  border-radius: 4px;
  display: table;
  margin: 4px auto;
  font-weight: 600;
  
  @media (max-width: 768px) {
	text-align: center;
	margin-bottom:${props => props.theme.spacing.doubleInset};
  }
  @media (max-width: 500px) {
    font-size: 36px;
  }
`;

const StyledColumn = styled(Column)`
	/* Landscape */
	@media only screen 
	and (min-device-width: 768px) 
	and (max-device-width: 1024px) 
	and (orientation: landscape) 
	and (-webkit-min-device-pixel-ratio: 1) {
		
	}
	@media only screen 
	and (min-device-width: 1024px) 
	and (max-device-width: 1024px) 
	and (orientation: portrait) 
	and (-webkit-min-device-pixel-ratio: 2) {
		max-width:calc( 100% );
	}
	@media ${device.laptop} {
		flex-direction:row;
	}
	@media(max-width:1024px) {
		flex-direction:row;
		flex-wrap:wrap;
	}
	@media ${device.mobileL} {
		flex-direction:column;
	}
`;

export default function HomeScreen({ acf }) {
	const { backgroundImage, subtitle, title, services } = acf;
	return (
		<Main
			direction="column"
		>
			<StyledContainer
				image={backgroundImage.url}
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<StyledRow>
					<Column width="100%" justifyContent="center">
						<TitleContainer>
							<Subtitle>{subtitle}</Subtitle>
							<Title>{title}</Title>
						</TitleContainer>
					</Column>
					<StyledColumn width="100%" justifyContent="end">
						{services.map((service, i) => <ServiceCard key={`service-cards-${service.ID}-${i}`} {...service} />)}
					</StyledColumn>
				</StyledRow>
			</StyledContainer>
		</Main>
	)
}