import React from "react";
import styled from "styled-components";
import { BASE_URL } from '../../../../utils/data';

const Container = styled.div`
    display:block;
    opacity: ${({ open }) => open ? "1" : "0"};
    visibility: ${({ open }) => open ? "visible" : "hidden"};
    transition:all 0.3s linear;

    background: #FFF;
    border-radius: 8px;
    position: absolute;
    top: 23px;
    z-index: 999;
    width: 240px;
    > :first-child {
        border-top: none;
    }
    @media (max-width: 768px) {
        display:flex;
        opacity: ${({ open }) => open ? "1" : "0"};
        visibility: ${({ open }) => open ? "visible" : "hidden"};
        overflow: ${({ open }) => open ? "scroll" : "hidden"};
        height: ${({ open }) => open ? "auto" : "0px"};
        transition:all 0.3s linear;
        
        background: ${({ home }) => home ? "transparent" : "#3c3c46"};
        border-radius: 0px;
        flex-direction: column;
        border-radius: 0px;
        position: initial;
        flex-direction: column;
        z-index: unset;
        width: 100%;
        text-align:right;
        padding: 10px 0px 0px 0px;
        > :last-child {
            margin-bottom: 30px;
        }
    }
`
const StyledLink = styled.a`
    color: ${p => p.active ? "#555555" : "#555555"};
    font-weight: bold;
    border-top: 0.5px solid #d7d7d7;
    font-size: 12px;
    padding: 24px 40px 24px 24px;
    text-decoration: none;
    text-transform: uppercase;
    display:block;
    width:100%;
    outline:none;

    :hover {
        color: #10bce0;
    }

    @media (max-width: 768px) {
        color: ${p => p.active ? "#10bce0" : "#10bce0"};
        border: 0px;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        align-self: flex-end;
        margin-bottom: 20px;
        margin-right: 0;
        padding: 0px;
    }
`

const Subnav = ({ open, links, path, onSubNavLinkClick, home }) => {
    return (
        <Container open={open} home={home}>
            {links && links.map(({ title, link }, i) => {
                link = link.replace(BASE_URL, '/');
                return (
                    <StyledLink key={i} href={link} onClick={() => onSubNavLinkClick()} active={link.includes(path) ? "true" : undefined}>
                        {title}
                    </StyledLink>
                )
            })}
        </Container>
    )
}
export default Subnav
