import React from 'react';
import styled from 'styled-components';

const StyledQuote = styled.h2`
    color: #10bce0;
    font-size: 34px;
    margin: 0;
`

export default function Quote({ quote }) {
    return(
        <StyledQuote>"{quote}"</StyledQuote>
    )
}