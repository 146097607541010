import styled, { css } from 'styled-components';
import { device } from '../base/mediaquery';

export const H1 = styled.h1`
    font-size:${props => props.theme.fontSizes.extraLarge};
    font-weight:${props => props.theme.fontWeight.bold};
    line-height:100%;
    color:${props => props.theme.colors[props.color]};
    letter-spacing:0;
    text-align:${props => props.theme.textAlign.left};
    text-transform:uppercase;

    @media ${device.mobileL} { 
        font-size:36px;
        line-height:57px;
    }
`;

export const H2 = styled(H1).attrs({
    as: "h2"
})`
    font-size:${props => props.theme.fontSizes.medium};
    font-weight:${props => props.theme.fontWeight.regular};
`;

export const H3 = styled(H1).attrs({
    as: "h3"
})`
    font-size:${props => props.theme.fontSizes.medium};
    font-weight:${props => props.theme.fontWeight.regular};
`;

export const H4 = styled(H1).attrs({
    as: "h4"
})`
    font-size:${props => props.theme.fontSizes.small};
    font-weight:${props => props.theme.fontWeight.regular};
`;

export const ParagraphCSS = css`
    font-size:${props => props.theme.fontSizes.extraSmall};
    line-height:20px;
    color:${props => props.theme.colors[props.color]};
    letter-spacing:0;
    text-align:${props => props.theme.textAlign[props.textAlign]};
    font-weight:${props => props.theme.fontWeight.regular};

    a {
        font-size:${props => props.theme.fontSizes.extraSmall};
        line-height:20px;
        color:${props => props.theme.colors[props.color]};
        letter-spacing:0;
        text-align:${props => props.theme.textAlign[props.textAlign]};
        font-weight:${props => props.theme.fontWeight.regular};
        text-decoration:none;
        margin-left:5px;
    }
`;

export const Paragraph = styled.p`
    ${ParagraphCSS}
`;
