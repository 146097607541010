import React from 'react';
import Image from './image';
import Quote from './quote';
import TextBlocks from './text-blocks';
import DownloadBlocks from './download-blocks';

const blocks = {
    'image': Image,
    'textBlocks': TextBlocks,
    'quote': Quote,
    'downloadBlocks': DownloadBlocks,
}

export default function ContentBlockTemplate({ acf_fc_layout, ...props }) {
    const BlockTemplate = blocks[acf_fc_layout];
    return <BlockTemplate {...props} />;
}