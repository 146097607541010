import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { getObjectsByType, getObjectById, BASE_URL } from "../../../utils/data";
import Loading from "../../loading/loadingImage";
import getFormattedDate from "../../../utils/dateFormat";

const ItemContainer = styled.div`
    width:calc(100% / 3);
    display:flex;
    flex-direction:column;
    margin-left:15px;
    margin-right:15px;

    @media(max-width:1024px) {
        width:100%;
        margin-bottom:30px;
        border-bottom:1px solid rgba(255,255,255, 0.2);
        padding-bottom:30px;
    }
    @media(max-width:480px) {
        margin-left:0;
        margin-right:0;
    }
`;

const Title = styled.h2`
  color: #10bce0;
  text-transform:uppercase;
  margin-bottom:30px;
  font-size: 18px;
  letter-spacing:1.1px;
`;

const Subtitle = styled.h3`
  color: #f3f3f3;
  font-size: 16px;
  margin-bottom: 1.1rem;
`;

const Text = styled.p`
    color: #a6a6ab;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 1rem;
    @media (max-width: 950px) {
        text-align: justify;
    }
    a {
		color:rgb(16, 188, 224);
	}
`;

const Date = styled.p`
  color: #17b7de;
  margin-bottom: 1rem;
`;

const Button = styled.a`
  background: #10bce0;
  color: #FFF;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  padding: 8px;
  max-width:120px;
  text-align:center;
`;

export default function FooterItem({ postType, select, title, postObject }) {
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                if (select === 'latestPost' && !postObject) {
                    const posts = await getObjectsByType(postType, { perPage: 1 });
                    let postData = posts[0];
                    postData.permalink = `/${postType}/${postData.post_name}`;
                    setPost(postData);
                } else {
                    const postData = await getObjectById(postObject.ID);
                    const {post_type, post_title, post_modified, permalink, ...rest} = postData;
                    setPost({post_type, post_title, post_modified, permalink: permalink.replace(BASE_URL, '/'), acf: rest});
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [postType, postObject, select])

    if (loading) return <Loading />
    const { post_type, post_title, post_date, permalink, acf } = post;
    return (
        <>
            <ItemContainer>
                <Title>{title}</Title>
                {post_type !== 'page' && <Subtitle>{post_title}</Subtitle>}
                {post_type === 'page' && acf?.sub_title && <Subtitle>{acf?.sub_title}</Subtitle>}
                <Text dangerouslySetInnerHTML={{ __html: acf?.shortPageDescription }} />
                {post_type !== 'project' && 
                    post_type !== 'page' && <Date>{getFormattedDate(post_date)}</Date>
                }
                <Button href={permalink}>read more</Button>
            </ItemContainer>
        </>
    )
}