import React from "react"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    padding-top: 16px;
    margin-top:calc(80px + 16px);
    > :last-child {
        border: none;
    }
`
const Crumb = styled.p`
    color: #10bce0;
    padding: 0 8px;
    text-transform: uppercase;
    font-size:14px;    
    line-height:21px;
    margin-bottom:0;
    display: block;
`
const CrumbLink = styled.a`
    color: #878787;
    padding: 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size:14px;
    line-height:21px;
    :hover{
        color: #10bce0;
    }
    &::after {
        content:"|";
        color:#878787;
        padding-left:7px;
        padding-right:7px;
    }
`

export default function BreadCrumbs({ url = '' }) {
    const crumbs = url.split('/').filter(el => el !== "");

    const setLink = crumb => {
        if (crumb === 'about') return '/about/';
        if (crumb === 'service') return '/services/';
        if (crumb === 'publication') return '/news-overview/publications/';
        if (crumb === 'news') return '/news-overview/';
        if (crumb === 'project') return '/projects/';
        if (crumb === 'team-member') return '/about/our-team/';
        if (crumb === 'corporate-finance') return '/service/corporate-finance/';
    }

    const setText = crumb => {
        if (crumb === 'service') return 'services';
        if (crumb === 'publication') return 'publications';
        if (crumb === 'project') return 'projects';
        if (crumb === 'news') return 'news';
        if (crumb === 'team-member') return 'our team';
        if (crumb === 'corporate-finance') return 'corporate finance';
        return crumb;
    }

    const formatActiveCrumb = crumb => {
        return crumb.replace(/-/g, ' ');
    }

    return (
        <Container>
            <CrumbLink href='/'>Home</CrumbLink>
            {
                crumbs.map((crumb, i) =>
                    (i < crumbs.length - 1)
                        ?
                        <CrumbLink key={i} href={setLink(crumb)}>{setText(crumb)}</CrumbLink>
                        :
                        <Crumb key={i}>{formatActiveCrumb(crumb)}</Crumb>)
            }
        </Container>
    )
}