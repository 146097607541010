import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { AibButton } from "."
import { getObjectsByType } from "../../../utils/data"

const ContentTitle = styled.h1`
  color: #10bce0;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: bold;
`;

const LatestItemsContainer = styled.div`
    padding: 16px 0;
    width:auto;
    @media(max-width:768px){
        display: flex;
        justify-content: space-between;
    }
    @media(max-width:960px){
        display: flex;
        flex-direction: column;
    }
`
const LatestItem = styled.div`
  padding: 8px 0;
  display: flex;
  align-items:flex-start;

  @media(max-width:960px) {
    align-items:flex-start;
  }
`
const Icon = styled(FontAwesomeIcon)`
  color: #10bce0;
  padding: 4px 0;
  margin-right: 8px;
`
const StyledLink = styled.a`
	max-width: 250px;
	display: flex;
	text-decoration: none;
	color: #303030;
	font-weight: bold;
	font-size: 16px;
	text-transform: capitalize;

	@media(max-width:480px) {
		width:100%;
	}
	@media all\0 {
		width:250px;
	}
`

const Latest = ({ postType, currentPostID, topScrollPositionRef, scrollToTop }) => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const resp = await getObjectsByType(postType, { perPage: 3 });
            setPosts(resp);
        }
        fetchData();
    }, [postType]);

    let title = 'projects';
    if (postType === 'publication') title = 'publications';
    if (postType === 'news') title = 'news';

    return (
        <>
            <ContentTitle>latest {title}</ContentTitle>
            <LatestItemsContainer>
                {posts.map(({ ID, post_title, post_type, post_name }, i) => {
                    if (currentPostID !== ID) {
                        return (
                            <LatestItem key={`latest-${post_type}-${i}`}>
                                <Icon icon={faCheckCircle} />
                                <StyledLink onClick={() => scrollToTop(topScrollPositionRef)} href={`/${post_type}/${post_name}`}>{post_title}</StyledLink>
                            </LatestItem>
                        );
                    }
                    return null;
                })}
            </LatestItemsContainer>
            <AibButton href={`/${postType === 'news' ? 'news-overview/' : 'projects/'}`}>View more</AibButton>
        </>
    )
}
export default Latest;