import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import GlobalStyles from './styles/theme/global-styles';
import Theme from './styles/theme/';
import Loading from './components/loading';
import Website from './components/website/';
import WebsiteError from './components/website-error';
import { getPostJSONData } from './utils/data';

const App = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [website, setWebsite] = useState({});

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const website = await getPostJSONData('website-info', 'false/website-info');
                setWebsite(website);
            }
            catch (error) {
                setError(error);
            }
            setLoading(false);
        }

        fetchData();
    }, []);

    if (loading) {
        return <Loading />;
    }
    else if (error) {
        return <WebsiteError error={error} />
    }
    else {
        return (
            <Theme>
                <GlobalStyles />
                <Router>
                    <Switch>
                        <Website website={website} />
                    </Switch>
                </Router>
            </Theme>
        );
    }
}
export default App;
