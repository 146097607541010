import React from "react"
import styled from "styled-components"
import { Container, Row, Column } from '../../base/grid';
import { device } from '../../base/mediaquery';
import Navigation from "./navigation"
import logo from "../../../images/icons/aib-logo-white.png"

const StyledContainer = styled(Container)`
    background-color:${props => props.theme.colors[props.bgColor]};
    height: 80px;
    position:absolute;
    width:100%;
    top:40px;
    z-index:9999;
`;

const StyledRow = styled(Row)`
    @media ${device.mobileL} {
        flex-direction:row;
        justify-content:${props => props.theme.justifyContent.spaceEvenly};
    }
`;

const StyledColumn = styled(Column)`
    @media ${device.laptop} {
        &:first-child {
            align-items:flex-start;
            margin-left:15px;
            max-width:calc(20% - 15px);
        }
        &:last-child {
            align-items:flex-end;
            margin-right:15px;
            max-width:calc(80% - 15px);
        }
    }
    @media ${device.mobileL} {
        max-width:calc( calc(100vw / 2) - 30px ) ;
        &:first-child {
            align-items:flex-start;
            margin-left:15px;
        }
        &:last-child {
            align-items:flex-end;
            margin-right:15px;
        }
    }
`;

const StyledLink = styled.a`
    display:block;
    height:auto;
    border:0 !important;
    outline:none;
`;

const Logo = styled.img`
    max-height: 60px;
    width:75px !important;
    border: 0px;
    outline:none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const NavigationBar = ({ onHomePage, acf }) => {
    const { menuItems } = acf;
    return (
        <StyledContainer 
            bgColor={onHomePage === true ? 'transparency' : 'secondary'}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <StyledRow>
                <StyledColumn width="20%">
                    <StyledLink href='/' title="AIB Bank Aruba">
                        <Logo src={logo} alt="AIB Bank Aruba" />
                    </StyledLink>
                </StyledColumn>
                <StyledColumn 
                    width="80%"
                    justifyContent="end"
                >
                    <Navigation menuItems={menuItems} home={onHomePage} />
                </StyledColumn>
            </StyledRow>
        </StyledContainer>
    )
}
export default NavigationBar;