import React from 'react';
import styled from 'styled-components';
import Preloader from './preloader.gif';

const Container = styled.div`
    height:auto;
    padding:30px;
    width:auto;
    display:flex;
    align-items:center;
    justify-content:center;
`;

const Image = styled.img`
    width: 25px;
    height: 25px;
    display: block;
    margin: 0 auto;
`;

const LoadingImages = () => {
    return (
        <Container>
            <Image src={Preloader} alt="preloader" />
        </Container>
    );
}
export default LoadingImages;