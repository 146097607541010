import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { GOOGLE_RECAPTCHA_CLIENT_CODE, gtagEvent } from '../../utils/google';
import { BASE_URL } from '../../utils/data';

const Form = styled.form`
  padding-bottom: 16px;
  display: flex;
  flex-direction:column;
  flex-wrap:wrap;
  width:100%;

  & .alert {
      border:1px solid red;
      background-color:pink;
      color:red;
      padding:15px 15px;
      height:48px;
      font-size:14px;
      line-height:14px;
      text-align:left;
      position:absolute;
      top:0;
      right:18px;
      border-radius:0;
  }
`;

const FormGroupContainer = styled.div`
    display:flex;
    flex-direction:${props => props.theme.direction[props.direction]};
    width:100%;
    margin-bottom:26px;
    justify-content:flex-start;

    @media(max-width:845px) {
        .mobile-fix {
            justify-content:flex-end;
            display:flex;
        }
        #subject,
        #message {
            width:100%;
        }
    }
    
`;

const FormGroup = styled.div`
    position:relative;
    width:100%;
`;

const Input = styled.input`
    width: calc(100% - 18px);
    padding: 8px;
    border: ${p => p.valid ? "1px solid red" : "1px solid #98999e"};
    height: 48px;
    color: #b0b0b0;

    :focus {
            outline: none;
            border:1px solid #98999e;
    }

    ::placeholder {
        color: #b0b0b0;
        font-family: 'Barlow', sans-serif;
    } 
`;

const TextArea = styled.textarea`
  padding: 12px 8px;
  width: calc(100% - 18px);
  border: ${p => p.valid ? "1px solid red" : "1px solid #98999e"};
    ::placeholder {
        color: #b0b0b0;
        font-family: 'Barlow', sans-serif;
    }
`;

const Button = styled.button`
  width: 100%;
  text-transform: capitalize;
  padding: 8px;
  height:48px;
  border: 0;
  font-size:14px;
  font-weight:bold;
  background-color:${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.defaultSecondary};
  cursor: ${p => p.state === 'sending' ? 'progress' : 'pointer'};
  display: ${p => p.state === 'success' ? 'none' : 'block'};
`;

const AS = styled.div`
    display: none;
`;

const MessageDone = styled.strong`
  letter-spacing:0.42px;
  font-size:15px;
  line-height:22px;
  margin-bottom:15px;
`;

const SubmitContainer = styled.div`
    width: calc(100% - 18px);

    @media(max-width:845px) {
        width: calc(100% );
    }
`;

const ContactForm = () => {
    const { handleSubmit, register, formState: { errors } } = useForm();

    const [state, setState] = React.useState('');

    React.useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_CLIENT_CODE}`;
        script.async = true;
        script.defer = true;
        script.style.display = 'none !important';
        document.body.appendChild(script);
    }, []);

    const onSubmit = data => {
        const gtagLabel = data?.subject;
        gtagEvent({
            action: 'submit_form',
            category: 'Contact',
            label: gtagLabel,
        });
        const grecaptcha = window?.grecaptcha;
        if (!grecaptcha) {
            console.log(`recaptcha object not defined`);
            return;
        }
        try {
            const reCaptchaAction = 'contactpage';
            grecaptcha.ready(function () {
                grecaptcha.execute(GOOGLE_RECAPTCHA_CLIENT_CODE, { action: reCaptchaAction }).then(function (token) {
                    let formData = new FormData();
                    for (const property in data) {
                        formData.set(property, data[property]);
                    }
                    formData.set('action', 'handle_contact_form_submission');
                    formData.set('token', token);
                    formData.set('recaptcha-action', reCaptchaAction);

                    setState('sending');
                    fetch(`${BASE_URL}wp-admin/admin-ajax.php`, {
                        method: 'POST',
                        body: formData
                    })
                        .then(response => response.json())
                        .then(async ({ status, result }) => {
                            // console.log(status, result);
                            const res = (status === 'success' && result) ? 'success' : 'error';
                            setState(res);
                        })
                        .catch(error => console.error(error))
                        .finally(_ => console.log('done'));
                });
            });
        }
        catch (error) {
            console.log('Error executing grecaptcha');
            console.log(error);
        }
    }

    let buttonText = 'Submit';
    if (state === 'success') buttonText = 'Thank you!';
    if (state === 'sending') buttonText = 'Submitting...';

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroupContainer direction="row">
                <FormGroup>
                    <Input id="fullName" name="fullName" type="text" placeholder="Full name" valid={errors.fullName} {...register('fullName', { required: true, maxLength: 25 })} />
                    {errors.fullName && <span className="alert" role="alert">Required</span>}
                </FormGroup>
                <FormGroup area="email" className="mobile-fix">
                    <Input id="email" name="email" type="text" placeholder="Email" valid={errors.email} {...register('email', { required: true })} />
                    {errors.email && <span className="alert" role="alert">Required</span>}
                </FormGroup>
            </FormGroupContainer>
            <FormGroupContainer direction="row">
                <FormGroup area="subject">
                    <Input id="subject" name="subject" type="text" placeholder="Subject" valid={errors.subject} {...register('subject', { required: true })} />
                    {errors.subject && (<span className="alert" role="alert">Required</span>)}
                </FormGroup>
            </FormGroupContainer>
            <FormGroupContainer direction="row">
                <FormGroup area="message">
                    <TextArea id="message" name="message" rows={8} placeholder="Message" valid={errors.message} {...register('message', { required: true })} />
                    {errors.message && (<span className="alert" role="alert">Required</span>)}
                </FormGroup>
            </FormGroupContainer>
            <AS>
                <Input type="text" id="url" name="url" placeholder="Leave this empty" {...register('url')} />
            </AS>
            <FormGroupContainer direction="row">
                <SubmitContainer>
                    {
                        state === 'success'
                            ?
                            <div style={{ paddingLeft: '8px' }}>
                                <MessageDone>Thank you for submitting your information. We'll contact you as soon as possible.</MessageDone>
                            </div>
                            :
                            <Button type="submit" disabled={(state === 'sending')} state={state}>{buttonText}</Button>

                    }
                </SubmitContainer>
            </FormGroupContainer>
        </Form>
    )
}
export default ContactForm;