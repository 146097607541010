import React from "react"
import styled from "styled-components"
import FooterItem from "./footerItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { iconMapper } from "../../../utils/icon-mapper"
import { Container, Row, Column } from '../../base/grid';

const StyledContainer = styled(Container)`
    background-color: #3c3c46;
    padding-top:${props => props.theme.spacing.tripleInset};
    padding-bottom:${props => props.theme.spacing.tripleInset}; 
`;

const Socials = styled.div`
  background: #3c3c46;
  padding-top: 40px;
  display: flex;
  justify-content:center;
  width:100%;
  margin-left: 15px;
  margin-top:30px;
  margin-bottom:30px;

  @media(max-width:846px) {
    margin-top:0;
    margin-bottom:30px;
  }
`;

const SocialLink = styled.a``;

const Icon = styled(FontAwesomeIcon)`
  color: #FFF;
`

const Paragraph = styled.p`
    margin-bottom:0;
    padding-top:25px;
    font-size:14px;
    text-align: center;
    color: #F3F3F3;
    width:100%;
    text-align:center;
`;

const Link = styled.a`
    font-size:14px;
    text-decoration:none;
    color: #17B6DE;
`;

const StyledColumn = styled(Column)`
    @media(max-width:1024px) {
        flex-direction:row;
        align-items:flex-start;
    }
    @media(max-width:1023px) {
        flex-direction:column;
    }
`;

export default function Footer({ onHomePage, acf }) {
    return (
        <StyledContainer 
            className={onHomePage === true ? 'hidden' : ''}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Row>
                <StyledColumn width="100%">
                    {acf?.contentColumns.map((contentColumn, i) => <FooterItem key={i} {...contentColumn} />)}
                </StyledColumn>
            </Row>
            {acf?.socialLinks?.length > 0 &&
                <Row>
                    <Column width="100%">
                        <Socials>
                            {acf?.socialLinks?.map(({ link, title, type }, i) =>
                                <SocialLink key={`social-media-link-${type}-${i}`} href={link} title={title} target="_blank" rel="noopener">
                                    <Icon key={i} icon={iconMapper[type]} />
                                </SocialLink>
                            )}
                        </Socials>
                    </Column>
                </Row>
            }
            <Row>
                <Column width="100%">
                    <Paragraph>By: <Link href="https://cr38te.com" title="By CR38TE" target="_blank" rel="noopener">CR38TE</Link></Paragraph>
                </Column>
            </Row>
        </StyledContainer>
    )
}