import React from "react"
import styled from "styled-components"

const ContentTitle = styled.h3`
  color: #10bce0;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 600;
`
const ContentText = styled.div`
  color: #6e6e6e;
  text-align: justify;
  a {
    color:${props => props.theme.colors.primary};
  }
`
const ContentSubTitle = styled.h4`
  color: #10bce0;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 600;
`
const Container = styled.div``
const SubContent = styled.div`
  margin: 0 0 32px 0;
`

export default function TextBlock({ title = '', textBlocks = [] }) {
    return (
        <Container>
            {title && <ContentTitle>{title}</ContentTitle>}
            {textBlocks && textBlocks?.map(({ paragraphHeading, textBlock }, i) =>
                <SubContent key={i}>
                    {paragraphHeading && <ContentSubTitle>{paragraphHeading}</ContentSubTitle>}
                    <ContentText dangerouslySetInnerHTML={{ __html: textBlock }} />
                </SubContent>
            )}
        </Container>
    )
}