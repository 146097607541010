const fetch = require('isomorphic-fetch');
export const BASE_URL = 'https://aib-bank.com/';
export const ENDPOINT = 'aib-bank-api/v1';

const handleResponse = async (response) => {
    const result = await response.json();
    if (result.type === 'ok') {
        return result.data
    }
    const { errorMessage, code, message, data } = result;
    let error = {
        errorMessage,
        code,
        message,
        status: data?.status,
    };
    throw (error);
}

export const getWebsiteInfo = async () => {
    const url = `${BASE_URL}wp-json/${ENDPOINT}/website-info`;
    const response = await fetch(url);
    return handleResponse(response);
}

export const getObjectsByType = async (type = '', options = {}) => {
    const perPage = options.perPage || -1;
    const offset = (options.page || 0) * perPage;
    let query = `offset=${offset}&posts_per_page=${perPage}`;
    if(options.category) query += `&cat=${options.category}`;
    if(options.year) query += `&year=${options.year}`;
    if(options.includeIDs) query += `${options.includeIDs.map(id => `&post__in[]=${id}`)}&orderby=post__in`;
    if(options.query) query += `&meta_key=${options.query.key}&meta_value=${options.query.value}`;
    const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/objects/${type}?${query}`);
    return handleResponse(response);
}

export const getObjectById = async (id = 1) => {
    const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/object-by-id/${id}`);
    return handleResponse(response);
}

export const getPostJSONData = async(type = '', slug= '') => {
    const response = await fetch(`${BASE_URL}wp-json/${ENDPOINT}/object-data/${type}/${slug}`);
    return handleResponse(response);
}