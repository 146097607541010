import React from "react"
import styled from "styled-components"
import { Container, Row, Column } from '../../base/grid';
import { device } from '../../base/mediaquery';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { iconMapper } from "../../../utils/icon-mapper"

const StyledContainer = styled(Container)`
    height: 40px;
    font-size: ${props => props.theme.fontSizes.extraSmall};
`;

const StyledColumn = styled(Column)`
    justify-content:flex-end;

    @media ${device.laptop} {
        flex-direction:row;
        justify-content:${props => props.theme.justifyContent.end};
    }

    @media (orientation: landscape) {
        justify-content:flex-end;
    }

    @media ${device.mobileL} {
        flex-direction:row;
        justify-content:flex-end;
    }
`;

const IconContainer = styled.div`
    margin-left: ${props => props.theme.spacing.inset};   
    
    @media (orientation: landscape) {
        display:flex;
        flex-direction:row;
    }

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
        display:flex;
        flex-direction:row;
    }
    @media(max-width:600px) {
        display:flex;
        flex-direction:row;
    }
`;

const IconLink = styled.a`
    display: flex;
    text-decoration: none;
    :hover {
        span {
            color: #10bce0;
        }
    }
`
const Icon = styled(FontAwesomeIcon)`
    color: #10bce0;
    margin-right: 8px;
    height: 17px;
    @media (max-width: 768px) {
        font-size: 24px;
        height: 20px;
    }
    @media (max-width: 768px) {
        display: ${({ type }) => type === 'address' ? 'none' : 'block'};
        line-height: 24px;
    }
`
const InfoText = styled.span`
    color: #98999e;
    font-weight: 500;
    text-transform: ${({ type }) => type === 'email' ? 'none' : 'capitalize'};
    @media (max-width: 768px) {
        display: ${({ type }) => type === 'address' ? 'none' : 'none'};
        line-height: 24px;
    }
    @media (max-width: 479px) {
        display: none;
    }
`
const SocialLink = styled.a`
    text-decoration:none;
`;

const TopBar = (props) => {
    const { contactSection = {}, socialLinks = [] } = props.acf;
    return (
        <StyledContainer 
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
        >
            <Row>
                <StyledColumn width="100%">
                    {contactSection.contactInfo.map(({ type, info, email }, i) =>
                        <IconContainer key={`contact-info-${i}`}>
                            {
                                (type === 'email') &&
                                <IconLink href={`mailto:${email}`} target="_blank" rel="noopener">
                                    <Icon icon={iconMapper[type]} />
                                    <InfoText type={type}>{email}</InfoText>
                                </IconLink>
                            }
                            {
                                (['phone', 'mobile'].includes(type)) &&
                                <IconLink href={`tel:${info}`}>
                                    <Icon icon={iconMapper[type]} />
                                    <InfoText type={type}>{info}</InfoText>
                                </IconLink>
                            }
                            {
                                (!['email', 'phone', 'mobile'].includes(type)) &&
                                <>
                                    <Icon icon={iconMapper[type]} type={type} />
                                    <InfoText type={type}>{info}</InfoText>
                                </>
                            }
                        </IconContainer>
                    )}
                    {socialLinks?.length > 0 &&
                        <>
                            {socialLinks?.map(({ link, title, type }, i) =>
                                <IconContainer key={`social-media-link-${type}-${i}`}>
                                    <SocialLink href={link} title={title} target="_blank" rel="noopener">
                                        <Icon key={i} icon={iconMapper[type]} />
                                    </SocialLink>
                                </IconContainer>
                            )}
                        </>
                    }
                </StyledColumn>
            </Row>
        </StyledContainer>
    )
}
export default TopBar