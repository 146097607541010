import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { H2, ParagraphCSS } from '../../components/base/typography';
import corporateFinance from '../../images/icons/service-corporate-finance.png';
import economicFinancial from '../../images/icons/service-economic-financial.png'
import investments from '../../images/icons/service-investments.png'
import programProject from '../../images/icons/service-program-project.png'
import { getObjectById } from "../../utils/data";

const Card = styled.div`
	margin:7px 7px 14px 7px;
	min-height:250px;
	background: ${props => props.theme.colors.primary};
	padding: 16px;
	border-radius: 4px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		width:calc( 320px );
	}

	@media(max-width:1024px) {
		width:45%;
		margin-top:14px;
		margin-bottom:7px;
		min-height:200px;
		&:nth-child(3) {
			margin-bottom:14px;
		}
		&:nth-child(4) {
			margin-bottom:14px;
		}
	}
	@media(max-width:740px) {
		width:100%;
		min-height:170px;
	}
	@media(max-width:480px) {
		width:calc(100% - 14px);
		margin:7px 7px 14px 7px;
	}
`;

const Heading = styled.div`
    display: flex;
    justify-content: space-between;
	flex-direction:row;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		width:70%;
	}
`;

const Title = styled(H2)`
    padding-top: 4px;
    color: #FFF;
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;

    @media(max-width: 1440px){
        font-size: 20px;
    }
	@media(max-width:480px) {
		line-height:20px;
	}
`
const Text = styled.div`
	${ParagraphCSS}
    color: #caf6ff;
	font-weight: 500;
	
	a {
		color:${props => props.theme.colors.defaultPrimary};
	}
`;

const Icon = styled.img`
    height: 70px;
    color: #FFF;

    @media(max-width: 1440px){
        height: 50px;
    }
`

const StyledLink = styled.a`
    text-decoration: none;
    color: ${props => props.theme.colors.defaultPrimary};
    text-transform: capitalize;
    font-weight: 400;
`

const Icons = {
    'corporate': corporateFinance,
    'economicFinancial': economicFinancial,
    'investments': investments,
    'programProject': programProject,
}

export default function ServiceCard(props) {
    const [ parentSlug, setParentSlug ] = useState(undefined);
    const { post_title, post_type, post_name, acf, post_parent } = props;
    useEffect(() => {
        async function fetchData() {
            try {
                if (post_parent) {
                    const postData = await getObjectById(post_parent);
                    setParentSlug(postData.post_name);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [post_parent]);

    const { serviceIcon = {}, shortPageDescription = '' } = acf;
    let link = '/';
    if (post_type === 'page' && parentSlug) {
        link = `/${parentSlug}/${post_name}/`;
    }
    else if (post_type !== 'page') {
        link = `/${post_type}/${post_name}/`;
    }
    return (
        <Card>
            <Heading>
                <Title className="title">{post_title}</Title>
                <Icon className="icon" src={Icons[serviceIcon] || corporateFinance} alt={`${post_title} - AIB Bank Aruba`} />
            </Heading>
            <Text>
                {shortPageDescription.substr(0, 160) + " ..."}
                <StyledLink href={link}> read more</StyledLink>
            </Text>
        </Card>
    )
}