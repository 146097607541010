import React from 'react';
import styled from 'styled-components';
import Preloader from './preloader-logo.gif';

const Container = styled.div`
    height: 100vh !important;
    width:100% !important;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    width: 300px;
    height: 300px;
    display: block;
    margin: 0 auto;
`;

const Loading = () => {
    return (
        <Container>
            <Image src={Preloader} alt="AIB BANK loading..." />
        </Container>
    );
}
export default Loading;