import React from "react"
import styled from "styled-components"
import { Container, Row, Column } from '../base/grid';
import ContentBlocksTemplate from '../content-blocks/content-block-template';

const StyledContainer = styled(Container)`
	margin-top:0;
`;

const StyledRow = styled(Row)`
	align-items:${props => props.theme.alignItems.start};
`;

const StyledColumn = styled(Column)`
	display:flex;
	flex-direction:${props => props.theme.direction.column};
`;

export const ContentBlocks = ({ acf }) => {
    const { contentBlocks } = acf;
    return (
        contentBlocks && contentBlocks.map(({ leftContentBlock, rightContentBlock }, i) =>
            <StyledContainer
                direction="row"
                justifyContent="center"
                key={`content-block-row-${i}`}
            >
                <StyledRow>
                    <StyledColumn width="50%" className="content-container">
                        {leftContentBlock && leftContentBlock.map((contentBlock, index) =>
                            <ContentBlocksTemplate key={`leftContentBlock-${index}`} {...contentBlock} />)}
                    </StyledColumn>
                    <StyledColumn width="50%" className="content-container">
                        {rightContentBlock && rightContentBlock.map((contentBlock, index) =>
                            <ContentBlocksTemplate key={`rightContentBlock-${index}`} {...contentBlock} />)}
                    </StyledColumn>
                </StyledRow>
            </StyledContainer>
        )
    )
}
export default ContentBlocks;