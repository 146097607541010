import styled from "styled-components"

export const AibButton = styled.a`
  background: #10bce0;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  padding: 8px;
  display: table;
  cursor: pointer;
  text-align:center;
`
export const Title = styled.h1`
  color: ${p => {
    if (p.color === "black") {
      return "#303030"
    } else {
      return "#17b7de"
    }
  }};
  font-size: ${p => {
    if (p.size === "s") {
      return "12px"
    } else if (p.size === "m") {
      return "16px"
    } else {
      return "20px"
    }
  }};
  font-weight: bold;
  text-transform: capitalize;
`
