import {
    faMobileAlt,
    faEnvelope,
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
    faFacebookF,
    faYoutube,
    faTwitter,
    faGooglePlusG,
    faInstagram,
    faLinkedinIn,
    faPinterest,
    faBehance,
    faSnapchat,
} from "@fortawesome/free-brands-svg-icons"

export const iconMapper = {
    phone: faMobileAlt,
    email: faEnvelope,
    address: faMapMarkerAlt,
    facebook: faFacebookF,
    youtube: faYoutube,
    googleplus: faGooglePlusG,
    instagram: faInstagram,
    linkedin: faLinkedinIn,
    pinterest: faPinterest,
    behance: faBehance,
    snapchat: faSnapchat,
    twitter: faTwitter,
}