export const GA_TRACKING_ID = 'G-PEWR838GTS';
export const GOOGLE_RECAPTCHA_CLIENT_CODE = '6Lcw9hEaAAAAACjL6xTOTwb1g-0AEO_EnE6jeWZ-';

export const gtagPageview = (url) => {
    if (window.gtag) {
        window.gtag('config', GA_TRACKING_ID, {
            page_path: url,
        })
    } else {
        console.log('gtag not defined');
    }
}

export const gtagEvent = ({ action, category, label, value }) => {
    if (window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        })
    } else {
        console.log('gtag not defined');
    }
}