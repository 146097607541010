import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Loading from '../loading/loadingImage';
import { getObjectsByType } from '../../utils/data';
import { paginate } from '../../utils/paginate';
import Article from "../shared/pageParts/article";
import Pagination from "../shared/pagination"

const Container = styled.div`
    flex-basis: 100%;
`;

const NewsItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export default function PublicationsProjects({ acf, topScrollPositionRef, scrollToTop }) {
    const { manualSelect, postType } = acf;
    const [pageNumber, setPageNumber] = useState(0);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const postsPerPage = 12;

    useEffect(() => {
        async function fetchData() {
            try {
                if (!manualSelect) {
                    setPosts(await getObjectsByType(postType));
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [manualSelect, postType])

    if (loading) return <Loading />
    const [pagedPosts, pages] = paginate(posts, postsPerPage, pageNumber);
    return (
        <Container>
            <NewsItemsContainer>
                {pagedPosts.map((post, i) => <Article key={i} {...post} />)}
            </NewsItemsContainer>
            {
                pages > 1 &&
                <Pagination
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pages={pages}
                    topScrollPositionRef={topScrollPositionRef}
                    scrollToTop={scrollToTop}
                />
            }
        </Container>
    )
}