import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight, } from "@fortawesome/free-solid-svg-icons"

const Container = styled.div`
  position: relative;
`
const Image = styled.img`
  width: 100%;
  max-width: 100%;
  height:auto;
  display:block;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  position: absolute;
  top:0;
  width: 100%;
  height:100%;
`
const SliderButton = styled(FontAwesomeIcon)`
  background: #10bce0;
  color: #FFF;
  padding: 8px 12px;
  margin: 0 16px;
  cursor: pointer;
  width:30px !important;
`

export default function Slider({ images }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const maxLength = images.length - 1;

    const previous = () => {
        currentIndex === 0
            ? setCurrentIndex(maxLength)
            : setCurrentIndex(currentIndex - 1)
    }

    const next = () => {
        currentIndex === maxLength
            ? setCurrentIndex(0)
            : setCurrentIndex(currentIndex + 1)
    }

    console.log(images);
    console.log(currentIndex);
    
    const { sizes = [], alt } = images[(images.length > currentIndex ? currentIndex : 0)];
    return (
        <Container>
            <Image src={sizes['article_gallery']} alt={alt} />
            {maxLength > 0 &&
              <ButtonsContainer>
                  <SliderButton icon={faChevronLeft} onClick={previous} />
                  <SliderButton icon={faChevronRight} onClick={next} />
              </ButtonsContainer>
            }
        </Container>
    )
}