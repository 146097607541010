import React, { useRef } from "react"
import styled from "styled-components"
import { Container, Row, Column } from '../../components/base/grid';
import TeamContent from "../../components/team-members"
import PageHeader from "../../components/shared/pageParts/pageHeader"
import BreadCrumbs from "../../components/shared/breadCrumbs"
import Services from "../../components/services"
import PublicationsProjects from "../../components/publications-projects"

const StyledContainer = styled(Container)`
	margin-bottom:${props => props.theme.spacing.tripleInset};
`;

const StyledRow = styled(Row)`
	display:flex;
	flex-direction:${props => props.theme.direction[props.direction]};
`;

const NewStyledColumn = styled(Column)`
	text-align:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:60px;
	margin-bottom:0;
`;

const OverviewContent = {
	'service': Services,
	'publication': PublicationsProjects,
	'project': PublicationsProjects,
	'news': PublicationsProjects,
	'team_member': TeamContent,
}

export default function Overview(props) {
	const { post_title, acf } = props;
	const Template = OverviewContent[acf?.postType] ? OverviewContent[acf.postType] : OverviewContent['service'];

	const pageDescription = (acf?.postType !== 'publication' && acf?.postType !== 'project' && acf?.postType !== 'news') ? acf.shortPageDescription : '';

	const topScrollPositionRef = useRef();
	const scrollToTop = ref => ref.current.scrollIntoView({ behavior: "smooth" });

	return (
		<StyledContainer
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<Row>
				<Column width="100%">
					<BreadCrumbs url={post_title} />
				</Column>
			</Row>
			<StyledRow direction="column">
				<NewStyledColumn width="100%">
					<PageHeader post_title={post_title} shortPageDescription={pageDescription} />
				</NewStyledColumn>
				<Column width="100%" justifyContent={OverviewContent['service'] ? 'center' : ''}>
					<Template {...props} topScrollPositionRef={topScrollPositionRef} scrollToTop={scrollToTop} />
				</Column>
			</StyledRow>
		</StyledContainer>
	)
}