import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PageHeader from "../../components/shared/pageParts/pageHeader"
import { Container, Row, Column } from '../../components/base/grid';
import Footer from '../../components/layout/footer';
import { getPostJSONData } from '../../utils/data';
import Loading from '../../components/loading';
import ContentBlocks from "../../components/content-blocks"
import BreadCrumbs from "../../components/shared/breadCrumbs";
import TopBar from "../../components/layout/top-bar";
import NavigationBar from "../../components/layout/navigation-bar";

const StyledContainer = styled(Container)`
    margin-bottom:${props => props.theme.spacing.tripleInset};
`;

const StyledColumn = styled(Column)`
    text-align:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:60px;
	margin-bottom:0;
`;

export default function SingleServiceScreen(props) {
    const [serviceData, setServiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = window.location.pathname.replace(/\/$/, '');

    useEffect(() => {
        async function fetchData() {
            const slug = props.match?.params?.slug;
            const parent = props.match?.params?.parent || 'false';
            try {
                setServiceData(await getPostJSONData('service', `${parent}/${slug}`));
                setLoading(false);
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [props]);

    if (loading) return <Loading />
    const { post_title } = serviceData;
    return (
        <>
            <TopBar {...props.contact} />
            <NavigationBar {...props.header} />
            <StyledContainer
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Row>
                    <Column width="100%">
                        <BreadCrumbs url={url} />
                    </Column>
                </Row>
                <Row>
                    <StyledColumn width="100%">
                        <PageHeader post_title={post_title} resetPadding={true} />
                    </StyledColumn>
                </Row>
                <ContentBlocks {...serviceData} />
            </StyledContainer>
            <Footer {...props.footer} />
        </>
    )
}