import React from "react"
import styled from "styled-components"
import getFormattedDate from "../../../../utils/dateFormat";
import excerptText from "../../../../utils/excerpt-text";

const Container = styled.div`
  flex: 0 0 calc(25%);
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 4px 4px 12px 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  min-width: 315px;
  width: 315px;
  @media(max-width:480px) {
    margin-left:0;
    margin-right:0;
    min-width: calc(100% - 30px);
    width:calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
  @media(max-width:812px) {
    min-width: 285px;
    width:285px;
    flex: 0 0 285px;
    margin-left:10px;
    margin-right:10px;
  }
  @media(max-width:667px) {
    margin-left:0;
    margin-right:0;
    min-width: calc(100% - 30px);
    width:calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
`;

const Image = styled.div`
  height: 200px;
  width: 100%;
  background: url('${p => p.image}') center/cover no-repeat;
`
const Label = styled.p`
  background: #10bce0;
  color: #FFF;
  padding: 4px 16px;
  margin: 16px;
  display: table;
  text-transform: uppercase;
`
const Title = styled.h1`
  text-transform: capitalize;
  color: #303030;
  font-size: 16px;
  font-weight: 600;
  padding: 0 8px;
  margin-top: 16px;
  min-height: 38px;
`
const Text = styled.p`
  padding: 0 8px;
  color: #6e6e6e;
  justify-self: start;
  margin-top: 16px;
  min-height: 76px;
`
const Footer = styled.div`
  width: calc(100% - 12px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-left: 8px;
`
const Date = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    text-transform: capitalize;
`

const StyledLink = styled.a`
  text-decoration: none;
  background: #10bce0;
  padding: 8px 10px;
  color: #FFF;
  text-transform: uppercase;
  cursor: pointer;
  font-size:14px;
`
export default function Article({ acf, post_type, post_name, post_title, post_date }) {
    return (
        <Container>
            <Image image={acf.featuredImage?.url}>
                <Label>{post_type}</Label>
            </Image>
            <Title>{post_title}</Title>
            <Text>{excerptText(acf.shortPageDescription, 160)}</Text>
            <Footer>
                {post_type !== 'project' &&
                    <Date>{getFormattedDate(post_date)}</Date>
                }
                <StyledLink href={`/${post_type}/${post_name}`}>Read more</StyledLink>
            </Footer>
        </Container>
    )
}