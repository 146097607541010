import React from "react"
import styled from "styled-components"

const Title = styled.h1`
	text-transform: uppercase;
	color: #303030;
	font-weight: bolder;
	margin: 0 auto;
	font-size:40px;
	max-width:1280px;

	@media(max-width:960px) {
		font-size:28px;
	}
`;

const BlueLine = styled.div`
	width: 40px;
	margin: 12px auto;
	border: 1px solid #00afc0;
`;

const Text = styled.p`
	text-align: center;
	color: #6e6e6e;
	max-width: 600px;
	font-size:16px;
	line-height:21px;
	margin: 0 auto 60px auto;
	a {
		color:rgb(16, 188, 224);
	}
`;

const PageHeader = ({ post_title, shortPageDescription, resetPadding = false }) =>
	<>
		<Title>{post_title}</Title>
		<BlueLine />
		<Text dangerouslySetInnerHTML={{ __html: shortPageDescription }} />
	</>
export default PageHeader