import React, { useState } from "react"
import styled from "styled-components"
import Subnav from "./subnav"
import { BASE_URL } from '../../../../utils/data';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const Navigation = styled.nav`
    display: flex;
    justify-content: ${props => props.theme.justifyContent.end};
    align-items: ${props => props.theme.alignItems.center};
    margin-right:8px;
    width:100%;

    @media (max-width: 768px) {
        opacity: ${({ opacity }) => opacity};
        transition:all 0.3s linear;
        visibility:${({ visibility }) => visibility};
        top: ${({ top }) => top};

        background: ${({ home }) => home ? "rgba(60, 60, 70, 0.85)" : "#3c3c46"};
        position: absolute;
        right: 0px;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px 16px;
        z-index: 1;
        > :first-child {
            margin-top: 0px;
        }
    }
    @media (max-width:480px) {
        right:-10px;
    }
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 1) {
        right:-15px;
    }
`;

const SubnavContainer = styled.div`
    position: relative;
    color: ${props => props.theme.colors.defaultSecondary};
    text-decoration: none;
    font-size:${props => props.theme.fontSizes.extraSmall};

    :hover {
        color: #10bce0;
    }

    text-transform: uppercase;
    cursor: pointer;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0px;
        align-self: flex-end;
    }
`

const StyledLink = styled.a`
    color: ${props => props.theme.colors.defaultSecondary};
    text-decoration: none;
    text-transform: uppercase;
    font-size:${props => props.theme.fontSizes.extraSmall};
    margin-left:30px;
    display: block;
    line-height:29px;

    &:hover {
        color: ${props => props.theme.colors.primary};
    }

    @media (max-width: 768px) {
        margin-bottom: 16px;
        align-self: flex-end;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    display: none;
    justify-self: end;
    color: #10bce0;
    font-size: 2rem;
    cursor:pointer;
    @media (max-width: 768px) {
        display: initial;
    }
`

const Button = styled.a`
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid #17b7de;
    color: #FFF;
    width: 142px;
    padding: 4px;
    border-radius: 24px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 768px) {
            display: none;
    }
`;

const NavigationComponent = ({ menuItems, home }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isSubnavOpen, setSubnavOpen] = useState('');
    const url = window.location.href.replace(/\/$/, '');
    const path = url.substr(url.lastIndexOf('/') + 1);

    // const onWindowClick = function (e) {
    //     const { id } = e.target;
    //     const subNavs = document.querySelectorAll('[data-type="open-sub-nav"]');
    //     let setSubNav = '';
    //     for (let i = 0; i < subNavs.length; i++) {
    //         const subNav = subNavs[i];
    //         if (subNav?.id === id && isSubnavOpen !== id) {
    //             setSubNav = id;
    //         }
    //     }
    //     setSubnavOpen(setSubNav);
    // }

    const closeNav = () => {
        setIsNavOpen(false);
        setSubnavOpen([]);
    }

    // useEffect(() => {
    //     window.addEventListener('click', onWindowClick);
    //     return () => window.removeEventListener('click', onWindowClick);
    // });

    const navStyle = {
        opacity: isNavOpen ? '1' : '0',
        marginTop: isNavOpen ? '0px' : '-100px',
        visibility: isNavOpen ? 'visible' : 'hidden',
        top: isNavOpen ? home ? '56px' : '56px' : '100px',
    };

    return (
        <>
            <Icon
                id="open-nav"
                icon={isNavOpen ? faTimes : faBars}
                onClick={() => setIsNavOpen(!isNavOpen)}
            />
            <Navigation home={home} {...navStyle}>
                {menuItems.map(({ title = '', linkType = '', link = '', hasSubmenu = false, submenuItems }, i) => {
                    link = link.replace(BASE_URL, '/').replace('/home/', '/');
                    if (linkType === 'Internal' || hasSubmenu === 'Yes') {
                        if (hasSubmenu === 'Yes') {
                            const id = `open-sub-nav-${i}`;
                            return (
                                <SubnavContainer
                                    id={id}
                                    data-type="open-sub-nav"
                                    key={i}
                                    open={isSubnavOpen === id}
                                    onMouseEnter={() => { setSubnavOpen(id) }}
                                    onMouseLeave={() => setSubnavOpen('')}
                                >
                                    <StyledLink key={i} href={link} active={link.includes(path) ? "true" : undefined}>
                                        {title}
                                    </StyledLink>
                                    <Subnav home={home} open={isSubnavOpen === id} links={submenuItems} path={path} onSubNavLinkClick={closeNav} />
                                </SubnavContainer>
                            )
                        }
                        return (
                            <StyledLink key={i} href={link} active={link.includes(path) ? "true" : undefined}>
                                {title}
                            </StyledLink>
                        )
                    }
                    if (linkType === 'Call to action') {
                        return (
                            <Button key={i} href={link}>{title}</Button>
                        )
                    }
                    return null;
                })}
            </Navigation>
        </>
    )
}
export default NavigationComponent;