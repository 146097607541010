import React from "react"
import styled from "styled-components"
import { Title, AibButton } from "../shared/pageParts"
import serviceIcon1 from "../../images/icons/service-corporate-finance-blue.png"
import serviceIcon2 from "../../images/icons/service-program-project-blue.png"
import serviceIcon3 from "../../images/icons/service-economic-financial-blue.png"
import serviceIcon4 from "../../images/icons/service-investments-blue.png"
import { BASE_URL } from "../../utils/data"

const Icons = {
    'corporate': serviceIcon1,
    'programProject': serviceIcon2,
    'economicFinancial': serviceIcon3,
    'investments': serviceIcon4,
}

const Container = styled.div`
	min-width: 320px;
	padding: 32px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	margin-bottom: 40px;
	margin-left:15px;
	margin-right:15px;

	@media(max-width:960px) {
		width: calc(100% / 2);
	}

	@media(max-width:480px) {
		width:calc(100vw - 60px);
		margin-left:0;
		margin-right:0;
	}

`;

const ServiceHighlight = styled.div`
    min-height: 200px;
`;

const Highlight = styled.a`
    text-decoration: none;
`

const HighlightTitle = styled(Title)`
  text-align: center;
  margin-bottom: 20px;
  color: #12b5c5;
  font-weight: 500;
`

const Icon = styled.img`
  height: 100px;
  width: 120px;
`
const TopCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export default function ServiceCard({ acf, post_title, post_type, post_name }) {
    return (
        <Container>
            <TopCardContainer>
                <Icon src={Icons[acf.serviceIcon]} />
                <Title color="black" size="m">{post_title}</Title>
            </TopCardContainer>
            <ServiceHighlight>
                {acf.highlights && acf.highlights.map(({ serviceLink, highlight }, i) => {
                    if (serviceLink) {
                        return (
                            <Highlight href={`/${serviceLink.replace(BASE_URL, '')}`} key={`service-highlight-${i}`}>
                                <HighlightTitle color="blue" size="m">{highlight}</HighlightTitle>
                            </Highlight>
                        )
                    } else if (highlight) {
                        return (
                            <HighlightTitle key={`service-highlight-${i}`} color="blue" size="m">{highlight}</HighlightTitle>
                        )
                    } else {
                        return null;
                    }
                })}
            </ServiceHighlight>
            <AibButton href={`/${post_type}/${post_name}`}>read more</AibButton>
        </Container>
    )
}