import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    *,
    *:after,
    *:before {
        box-sizing:border-box;
    }
    html { scroll-behavior: smooth; }
    body {
        margin:0;
        padding:0;
        font-family: ${props => props.theme.fonts.paragraphFont};
        color: ${props => props.theme.colors.defaultPrimary};
        background-color: ${props => props.theme.colors.defaultSecondary};
        font-size: ${props => props.theme.fontSizes.small};
        font-weight: ${props => props.theme.fontWeight.regular};
        font-display:fallback;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: ${props => props.theme.fonts.headingFont};
        font-display:fallback;
        margin-top:0;
        margin-bottom:${props => props.theme.spacing.inset};
    }
    p, 
    ul > li {
        margin-top:0;
        margin-bottom:15px;
        font-size: 16px;
        line-height:21px;
    }
    ul > li {
        margin-bottom:0px;
    }
    .grecaptcha-badge, 
    .hidden {
        display: none !important;
        visibility:hidden;
    }
`;