import React from "react";
import styled from "styled-components";
import { Container, Row, Column } from '../../components/base/grid';
import BreadCrumbs from '../../components/shared/breadCrumbs';
import PageHeader from '../../components/shared/pageParts/pageHeader';
import CustomTable from "./custom-table";

const StyledContainer = styled(Container)`
	margin-bottom:${props => props.theme.spacing.tripleInset};
`;

const StyledRow = styled(Row)`
	@media(max-width:480px) {
		max-width:calc( 100% - 30px );
	}
`;

const StyledColumn = styled(Column)`
	text-align:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:60px;
	margin-bottom:0;

	&.custom-table {
		margin-top:0px;
		@media(max-width:480px) {
			width:100%;
			margin:0 auto;
		}
	}
`;

export default function DownloadScreen(props) {
    return (
		<StyledContainer
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<Row>
				<Column width="100%">
					<BreadCrumbs url={props.post_title} />
				</Column>
			</Row>
			<Row>
				<StyledColumn width="100%">
					<PageHeader shortPageDescription={props.acf.shortPageDescription} {...props} />
				</StyledColumn>
			</Row>
			<StyledRow>
				<StyledColumn width="100%" className="custom-table">
					<CustomTable {...props} />
				</StyledColumn>
			</StyledRow>
		</StyledContainer>
    )
}