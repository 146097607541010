import React from "react";
import styled from "styled-components";
import { Container, Row, Column } from '../../components/base/grid';
import BreadCrumbs from '../../components/shared/breadCrumbs';
import PageHeader from '../../components/shared/pageParts/pageHeader';
import ContentBlocks from "../../components/content-blocks";

const StyledContainer = styled(Container)`
	margin-bottom:${props => props.theme.spacing.tripleInset};
`;

const StyledColumn = styled(Column)`
	text-align:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:60px;
	margin-bottom:0;
`;

export default function LangingScreen(props) {
	return (
		<StyledContainer
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<Row>
				<Column width="100%">
					<BreadCrumbs url={props?.match?.url} />
				</Column>
			</Row>
			<Row>
				<StyledColumn width="100%">
					<PageHeader shortPageDescription={props.acf.shortPageDescription} {...props} />
				</StyledColumn>
			</Row>
			<ContentBlocks {...props} />
		</StyledContainer>
	)
}