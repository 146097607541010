import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
  align-self: start;
  margin: 0px 0px 24px 0px;
`

export default function Image({ image = '' }) {
    return (
        <StyledImage src={image?.sizes?.large} alt={image.alt} />
    )
}