import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Container, Row, Column } from '../../components/base/grid';
import PageHeader from "../../components/shared/pageParts/pageHeader"
import { iconMapper } from "../../utils/icon-mapper"
import { Title } from "../../components/shared/pageParts"
import ContactForm from "./conact-form"
import BreadCrumbs from "../../components/shared/breadCrumbs"

const StyledContainer = styled(Container)`
    .page-intro {
        text-align:center;
        margin:60px auto;
    }
`;

const StyledPageColumn = styled(Column)`
    text-align:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:60px;
`;

const ContactInfoContainer = styled.div`
    padding: 40px;
    display:flex;
    flex-direction:column;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin-bottom:60px;
    width:100%;

    @media(max-width:480px) {
        padding:15px;
    }
`;

const StyledRow = styled(Row)`
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    &:first-child {
        margin-bottom:60px;

        @media(max-width:845px) {
            margin-bottom:30px;
        }
    }
    @media(max-width:1024px) {
        max-width:100%;
    }
`;

const Contact = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
`;

const IconContainer = styled.div`
  display: flex;
  padding: 0 8px 8px 0px;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  color: #10bce0;
  cursor: pointer;
`;

const InfoText = styled.p`
  color: #98999e;
`;

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 160px;
  padding-bottom: 16px;
`;

const SocialLink = styled.a`
  color: #98999e;
`;

const OpeningHours = styled.div`
  padding-bottom: 16px;
`;

const Text = styled.p`
  color: #98999e;
  text-align: justify;
`;

const ContactFormContainer = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
`;

const StyledContactForm = styled(ContactForm)``;


const MapContainer = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    @media(max-width:480px) {
        margin-bottom:30px;
    }
`;

const Map = styled.iframe``;

const StyledRowNew = styled(Row)`
    @media(max-width:845px) {
        max-width:100%;
    }
`;

const IconLinkContainer = styled.a`
    display: flex;
    padding: 0 8px 8px 0px;
    align-items: center;
    text-decoration: none;
    :hover {
        p {
            color: #10bce0;
        }
    }
`;

const StyledColumn = styled(Column)`
    @media(max-width:1024px) {
        max-width:50%;
        width:50%;
        align-items:flex-start;
    }
    @media(max-width:846px) {
        align-items:flex-start;
        max-width:100%;
        width:100%;
    }
`;

export default function contactInfoScreen({ post_title, acf, contact }) {
    const { shortPageDescription, formTitle, contactInfoTitle, openingHoursTitle, mapTitle, openingHours } = acf;
    const { contactSection, socialLinks } = contact.acf;
    return (
        <StyledContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Row>
                <Column width="100%">
                    <BreadCrumbs url={post_title} />
                </Column>
            </Row>
            <Row>
                <StyledPageColumn width="100%">
                    <PageHeader post_title={post_title} shortPageDescription={shortPageDescription} />
                </StyledPageColumn>
            </Row>
            <StyledRowNew>
                <Column width="100%">
                    <ContactInfoContainer>
                        <StyledRow>
                            <StyledColumn width="50%">
                                <Contact>
                                    <Title color="black" size="l" style={{ marginBottom: "16px" }}>{contactInfoTitle}</Title>
                                    {
                                        contactSection && contactSection.contactInfo.map(({ type, info, email }, i) => {
                                            return (
                                                <IconContainer key={`contact-info-${i}`}>
                                                    {
                                                        (type === 'email') &&
                                                        <IconLinkContainer href={`mailto:${email}`}>
                                                            <Icon icon={iconMapper[type]} />
                                                            <InfoText type={type}>{email}</InfoText>
                                                        </IconLinkContainer>
                                                    }
                                                    {
                                                        (['phone', 'mobile'].includes(type)) &&
                                                        <IconLinkContainer href={`tel:${info}`}>
                                                            <Icon icon={iconMapper[type]} />
                                                            <InfoText type={type}>{info}</InfoText>
                                                        </IconLinkContainer>
                                                    }
                                                    {
                                                        (!['email', 'phone', 'mobile'].includes(type)) &&
                                                        <>
                                                            <Icon icon={iconMapper[type]} type={type} />
                                                            <InfoText type={type}>{info}</InfoText>
                                                        </>
                                                    }
                                                </IconContainer>
                                            )
                                        })
                                    }
                                    <Socials>
                                        {socialLinks && socialLinks.map(({ link, title, type }, i) =>
                                            <SocialLink key={`social-media-link-${type}-${i}`} href={link} title={title} target="_blank" rel="noopener">
                                                <Icon color="gray" key={i} icon={iconMapper[type]} />
                                            </SocialLink>
                                        )}
                                    </Socials>
                                </Contact>
                            </StyledColumn>
                            <StyledColumn width="50%">
                                <OpeningHours>
                                    <Title color="black" size="l" style={{ marginBottom: "16px" }}>{openingHoursTitle}</Title>
                                    <Text dangerouslySetInnerHTML={{ __html: openingHours }} />
                                </OpeningHours>
                            </StyledColumn>
                        </StyledRow>
                        <StyledRow>
                            <StyledColumn width="50%">
                                <ContactFormContainer>
                                    <Title color="black" size="l" style={{ marginBottom: "16px" }}>{formTitle}</Title>
                                    <StyledContactForm />
                                </ContactFormContainer>
                            </StyledColumn>
                            <StyledColumn width="50%">
                                <MapContainer>
                                    <Title color="black" size="l" style={{ marginBottom: "16px" }}>{mapTitle}</Title>
                                    <Map
                                        title="Location AIB Bank"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3894.966589629748!2d-70.03652278473236!3d12.518372328046928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e85389645baf03b%3A0x72c2b560b423ef85!2sWilhelminastraat%2036%2C%20Oranjestad%2C%20Aruba!5e0!3m2!1sen!2snl!4v1582652337162!5m2!1sen!2snl"
                                        width="100%"
                                        height="360"
                                        frameBorder="0"
                                        allowFullScreen
                                    ></Map>
                                </MapContainer>
                            </StyledColumn>
                        </StyledRow>
                    </ContactInfoContainer>
                </Column>
            </StyledRowNew>
        </StyledContainer>
    )
}