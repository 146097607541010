import React, { useState, useEffect } from 'react';
import Footer from '../../components/layout/footer';
import { getPostJSONData } from '../../utils/data';
import Loading from '../loading';
import PageError from '../page-error';
import HomeScreen from '../../screens/home-screen/new-index';
import LandingScreen from '../../screens/landing-screen';
import Overview from '../../screens/overview';
import ContactInfoScreen from '../../screens/contact-info-screen';
import TopBar from '../../components/layout/top-bar';
import NavigationBar from '../../components/layout/navigation-bar';
import DownloadScreen from '../../screens/download-screen';
import styled from "styled-components"
import {isIE, browserVersion} from 'react-device-detect';

const components = {
    'index': HomeScreen,
    'landing.php': LandingScreen,
    'overview.php': Overview,
    'contact.php': ContactInfoScreen,
    'download.php': DownloadScreen,
}

const Header = styled.header`
    display:flex;
    flex-direction:column;
    width:100%;
`;

export default function Page(props) {
    const { post_name, parent_slug, template } = props;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [content, setContent] = useState({});

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                let title = post_name || 'page';
                const path = `${(parent_slug && parent_slug !== 0) ? `${parent_slug}/` : 'false/'}${title}`
                const content = await getPostJSONData('page', path);
                setContent(content);
            }
            catch (error) {
                setError(error);
            }
            setLoading(false);
        }
        fetchData();
    }, [post_name, parent_slug]);

    if (loading) return <Loading />;
    if (error) return <PageError error={error} />;

    const Template = components[template] ? components[template] : components['index'];

    if (isIE) {
        if(browserVersion === "10") {
            alert('You are using an old Internet Explorer that is not supported by Microsoft, please upgrade for security reasons.')
        }
    } 

    return (
        <>
            <Header role="header">
                <TopBar {...props.contact} />
                <NavigationBar onHomePage={props.location.pathname === '/' ? true : false} {...props.header} />
            </Header>
            <Template {...content} {...props} />
            <Footer onHomePage={props.location.pathname === '/' ? true : false} {...props.footer} />
        </>
    )
}