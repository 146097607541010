import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

const Container = styled.div`
  display: flex;
  margin: 40px auto;
  justify-content: center;
`

const Icon = styled(FontAwesomeIcon)`
  background: #10bce0;
  width: 20px !important;
  height: 20px;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  margin: 0 4px;
  border: 1px solid #a0a0a0;
`
const PageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 20px !important;
  height: 20px;
  padding: 8px;
  margin: 0 4px;
  color: ${p => (p.active ? "#fff" : "#848484")};
  border: 1px solid #c0c0c0;
  cursor: pointer;
  background: ${p => (p.active ? "#10bce0" : "#fff")};
`

const Pagination = ({ pages, pageNumber, setPageNumber, topScrollPositionRef, scrollToTop }) => {
    const pageBlocks = [];
    for (let i = 0; i < pages; i++) {
        pageBlocks.push(
            <PageBlock key={i} active={pageNumber === i} onClick={() => setPageNumber(i)}>
                {i + 1}
            </PageBlock>
        )
    }

    const onPaginationClick = pageNumber => {
        setPageNumber(pageNumber);
        scrollToTop(topScrollPositionRef);
    }

    return (
        <Container>
            <Icon icon={faChevronLeft} onClick={() => onPaginationClick(pageNumber - 1)} />
            {pageBlocks}
            <Icon icon={faChevronRight} onClick={() => onPaginationClick(pageNumber + 1)} />
        </Container>
    )
}
export default Pagination