import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Loading from '../loading/loadingImage';
import { getObjectsByType } from '../../utils/data';
import { paginate } from '../../utils/paginate';

import Pagination from "../shared/pagination"
import MemberCard from "./team-member-card"

const Container = styled.div`
    padding: 0px 100px;
    @media (max-width: 1366px) {
        padding: 0px 50px;
    }
    @media (max-width: 1024px) {
        padding: 0px
    }
    @media (max-width: 950px) {
        padding: 0px 75px;
    }
    @media (max-width: 768px) {
        padding: 0px;
    }
`

const MembersContainer = styled.div`
    /* display: grid; */
    /* grid-gap: 60px; */
    /* grid-template-columns: repeat(3, 1fr); */
    justify-items: center;
    justify-content: center;
    @media (max-width: 1280px) {
        /* grid-gap: 30px; */
    }
    @media (max-width: 950px) {
        /* grid-template-columns: repeat(2, 1fr); */
        /* grid-column-gap: 0px; */
        /* grid-gap: 60px; */
    }
    @media (max-width: 600px) {
        /* grid-template-columns: repeat(1, 1fr); */
    }
`

export default function TeamMembers({ acf }) {
    const { manualSelect, teamMembers } = acf;
    const [pageNumber, setPageNumber] = useState(0);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const postsPerPage = 30;

    useEffect(() => {
        async function fetchData() {
            try {
                if (manualSelect) {
                    if (teamMembers) {
                        const posts = await getObjectsByType('team_member', { includeIDs: teamMembers });
                        setPosts(posts);
                    }
                } else {
                    const posts = await getObjectsByType('team_member');
                    setPosts(posts);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [manualSelect, teamMembers])
    if (loading) return <Loading />
    const [pagedPosts, pages] = paginate(posts, postsPerPage, pageNumber);
    return (
        <Container>
            <MembersContainer>
                {pagedPosts.map((member, i) => <MemberCard key={i} {...member} />)}
            </MembersContainer>
            {
                pages > 1 &&
                <Pagination
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    pages={pages}
                />
            }
        </Container>
    )
}