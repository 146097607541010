import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const ButtonText = styled.p`
    color: #FFF;
    text-transform: capitalize;
    font-weight: bold;
`
const Button = styled.a`
    display: flex;
    background: #10bce0;
    border-radius: 8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    height: 35px;
    cursor: pointer;
    text-decoration: none;
`
const Icon = styled(FontAwesomeIcon)`
    color: #FFF;
    padding-right: 8px;
    font-size: 20px;
`
const ContentText = styled.p`
    color: #6e6e6e;
`
const Container = styled.div`
    margin: 0px 0px 32px 0px;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    /* grid-gap: 24px; */
    display: flex;
    flex-direction: column;
    padding: 24px;
    @media(max-width:480px) {
        /* grid-template-columns: 1fr; */
    }
`;

const DownloadBlockContainer = styled.div`
    /* display: grid; */
    /* grid-template-columns: 1fr; */
    /* grid-gap: 24px; */
    display: flex;
    flex-direction: column;
    padding: 24px;
`;

export default function DownloadBlocks({ downloadBlocks }) {
    return (
        <Container>
            {downloadBlocks && downloadBlocks.map(({ description, buttonText, file }, i) =>
                <DownloadBlockContainer key={`download-block-${i}`}>
                    <ContentText>{description}</ContentText>
                    {
                        file &&
                        <Button href={file.url} target="_blank" rel="noopener" title={file.filename}>
                            <Icon icon={faCheckCircle} />
                            <ButtonText>{buttonText}</ButtonText>
                        </Button>
                    }
                </DownloadBlockContainer>
            )}
        </Container>
    )
}