import React, { useState, useEffect } from "react"
import ServiceCard from "./serviceCard"
import Loading from '../loading/loadingImage';
import { getObjectsByType } from '../../utils/data';

export default function Services({ acf }) {
	const { services, manualSelect } = acf;
	const [posts, setPosts] = useState(services);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchData() {
			try {
				if (!manualSelect) {
					setPosts(await getObjectsByType('service'));
				}
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
		fetchData();
	}, [manualSelect])
	if (loading) return <Loading />
	return (
		<>
			{posts && posts.map((service, i) => <ServiceCard key={`service-card-${i}-${service.ID}`} {...service} />)}
		</>
	)
}