import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Container, Row, Column } from '../../components/base/grid';
import PageHeader from "../../components/shared/pageParts/pageHeader"
import Slider from "../../components/shared/pageParts/slider"
import { getPostJSONData } from '../../utils/data';
import Loading from "../../components/loading";
import Footer from "../../components/layout/footer";
import Latest from '../../components/shared/pageParts/latest';
import BreadCrumbs from "../../components/shared/breadCrumbs";
import TopBar from "../../components/layout/top-bar";
import NavigationBar from "../../components/layout/navigation-bar";

const StyledContainer = styled(Container)``;

const StyledRow = styled(Row)`
    display:flex;
    flex-direction:row-reverse;
    align-items:flex-start;
    justify-content:center;
    margin-bottom:${props => props.theme.spacing.tripleInset};

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        flex-direction: column-reverse;
    }

    @media(max-width:1024px) {
        flex-direction: column-reverse;
    }
`;

const StyledColumn = styled(Column)`
    text-align:center;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	margin-top:60px;
	margin-bottom:0;
`;

const ColumnArticle = styled(Column)`
    display:flex;
    flex-direction:column;

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        align-items:flex-start;
        margin-bottom:${props => props.theme.spacing.doubleInset};
    }

    @media(max-width:1024px) {
        align-items:flex-start;
        margin-bottom:${props => props.theme.spacing.doubleInset};
    }
`;

const ColumnLatest = styled(Column)`
    display:flex;
    flex-direction:column;
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        align-items:flex-start;
    }
    @media(max-width:1024px) {
        align-items:flex-start;
    }
`;

const SliderContainer = styled.div`
  height: auto;
  margin-bottom:${props => props.theme.spacing.doubleInset};


  @media(max-width:960px) {
    margin:0 auto 60px auto;
  }
`
const Section = styled.div`

`
const ProjectDetails = styled.div`

`
const Detail = styled.div`
  display: flex;
`
const Value = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #3c3c46;
  text-transform: uppercase;
  margin-left: 8px;
`
const ContentTitle = styled.h2`
  color: #10bce0;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
`
const Label = styled(ContentTitle)`
  color: #10bce0;
  font-size:14px;
`
const ContentText = styled.p`
  color: #6e6e6e;
  margin-bottom: 30px;
  text-align: justify;
  @media (max-width: 1024px){
      text-align: justify;
  }
`

const StyledQuote = styled.h2`
    color: #10bce0;
    font-size: 16px;
    margin: 0;
`

export default function SingleArticleScreen(props) {
    const path = props.match.path;
    const postType = path.substring(1, path.indexOf('/:slug'));
    const articleSlug = props.match.params.slug;
    const [articleData, setArticleData] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = window.location.pathname.replace(/\/$/, '');

    useEffect(() => {
        async function fetchData() {
            try {
                if (articleSlug) {
                    setArticleData(await getPostJSONData(postType, `false/${articleSlug}`));
                    setLoading(false);
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [articleSlug, postType]);

    const topScrollPositionRef = useRef();
    const scrollToTop = ref => ref.current.scrollIntoView({ behavior: "smooth" });

    if (loading) return <Loading />
    const { ID, post_title, acf } = articleData;
    const { shortPageDescription, contentBlock } = acf;

    return (
        <>
            <TopBar {...props.contact} />
            <NavigationBar {...props.header} />
            <StyledContainer
                direction="column"
                alignItems="center"
                justifyContent="center"
                ref={topScrollPositionRef}
            >
                <Row>
                    <Column width="100%">
                        <BreadCrumbs url={url}/>
                    </Column>
                </Row>
                <Row>
                    <StyledColumn width="100%">
                        <PageHeader post_title={post_title} shortPageDescription={shortPageDescription} />
                    </StyledColumn>
                </Row>
            </StyledContainer>
            <StyledContainer
                direction="column"
                alignItems="center"
                justifyContent="start"
            >
                <StyledRow>
                    <Column width="10%"></Column>
                    <ColumnLatest width="20%">
                        <Latest postType={postType} currentPostID={ID} topScrollPositionRef={topScrollPositionRef} scrollToTop={scrollToTop} />
                    </ColumnLatest>
                    <ColumnArticle width="60%">
                        {contentBlock && contentBlock.map(({ acf_fc_layout, ...content }, i) => {
                            if (acf_fc_layout === 'textBlocks') {
                                return (
                                    <Section key={`text-content-block-${i}`}>
                                        {content.title &&<ContentTitle>{content.title}</ContentTitle>}
                                        {content.textBlocks && content.textBlocks.map(({ textBlock }, j) => <ContentText key={`text-block-${i}-${j}`}>{textBlock}</ContentText>)}
                                    </Section>
                                )
                            } else if (acf_fc_layout === 'gallery') {
                                return (
                                    <SliderContainer key={`gallery-content-block-${i}`}>
                                        <Slider images={content.gallery} />
                                    </SliderContainer>
                                )
                            } else if (acf_fc_layout === 'attributes') {
                                return (
                                    <React.Fragment key={`attributes-content-block-${i}`}>
                                        {content.attributes && (
                                            <ProjectDetails>
                                                {content.attributes.map(({ key, value }, i) => (
                                                    <Detail key={`attribute-detail-${i}`}>
                                                        <Label>{key}:</Label>
                                                        <Value>{value}</Value>
                                                    </Detail>
                                                ))}
                                            </ProjectDetails>
                                        )}
                                    </React.Fragment>
                                )
                            } else if (acf_fc_layout === 'quote') {
                                return (
                                    <StyledQuote key={`attributes-content-block-quote-${i}`}>"{content.quote}"</StyledQuote>
                                )
                            }
                            return null;
                        })}
                    </ColumnArticle>
                    <Column width="10%"></Column>
                </StyledRow>
            </StyledContainer>
            <Footer {...props.footer} />
        </>
    )
}