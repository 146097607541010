import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faFilePdf, faFile } from "@fortawesome/free-solid-svg-icons"

const Container = styled.div`
    margin-bottom: 30px;
    width:100%;

    @media (max-width:480px) {
        overflow-x:scroll;
    }
`;

const Table = styled.table`
    width: 100%;
`;

const Thead = styled.thead`
    background-color: #10BCE0;
`;

const Tbody = styled.tbody`
    tr:nth-child( even ) {
        background-color:#c1d5e7;
    }
    tr:nth-child( odd ) {
        background-color:#f8f8fe;
    }
`;

const TableRow = styled.tr`
    justify-items: stretch;
    text-align:left;
    vertical-align: top;
    width:100%;
`;

const Th = styled.th`
    color: #FFFFFF;
    font-weight:500;
    padding:15px;
    font-size: 16px;
    line-height: 21px;
    width:${props=>[props.width]};
`;

const Td = styled.td`
    padding:15px;
    color:#6e6e6e;
    font-size: 16px;
    line-height: 32px;
`;

const Button = styled.a`
    display: flex;
    background: #10bce0;
    border-radius: 8px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    height: 35px;
    width:auto;
    cursor: pointer;
    text-decoration: none;
    font-size:14px;
    line-height:26px;
    color:#fff;
    transition:all 0.2s linear;

    &:hover {
        background-color:#3c3c46;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color:${props=>[props.color]};
    padding-right: 8px;
    font-size:${props=>[props.size]};
`;

export default function CustomTable({ acf }) {
    const { downloads = [] } = acf;
    console.log(downloads);
    return (
        <Container>
            <Table>
                <Thead>
                    <TableRow>
                        <Th width="10%">File</Th>
                        <Th width="70%">Title</Th>
                        <Th width="20%">Download</Th>
                    </TableRow>
                </Thead>
                <Tbody>
                    {downloads.length && downloads.map(({ file = {} }, i) => {
                        const { title, url, filename, subtype } = file;
                        return (
                            <TableRow key={`download-${i}`} >
                                <Td>
                                    <Icon icon={subtype === 'pdf' ? faFilePdf : faFile} color="##3c3c46" size="30px" />
                                </Td>
                                <Td>{title}</Td>
                                <Td>
                                    <Button href={url} target="_blank" rel="noopener" title={filename}>
                                        <Icon icon={faCheckCircle} color="#FFF" size="30px" />
                                        Download
                                    </Button>
                                </Td>
                            </TableRow>
                        )
                    })}

                </Tbody>
            </Table>
        </Container>
    )
}