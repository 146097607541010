import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PageHeader from "../../components/shared/pageParts/pageHeader"
import TeamMemberCard from "../../components/team-members/team-member-card"
import { getPostJSONData } from '../../utils/data';
import Loading from "../../components/loading";
import Footer from "../../components/layout/footer";
import BreadCrumbs from "../../components/shared/breadCrumbs";
import TopBar from "../../components/layout/top-bar";
import NavigationBar from "../../components/layout/navigation-bar";

const Container = styled.div`
    padding: 0px 250px;
    padding-bottom: 50px;
`
const ContentContainer = styled.div`
  /* max-width: 848px; */
  /* margin: 0 auto; */
  /* display: grid; */
  /* grid-template-columns: auto 1fr; */
  /* grid-gap: 50px; */
  display: flex;
  justify-items: center;
  padding: 0px 90px;
  @media (max-width: 950px) {
    /* grid-template-columns: 1fr; */
    padding-bottom: 40px;
  }
`
const Content = styled.div``;

const DualContent = styled.div`
  /* display: grid; */
  /* grid-template-columns: auto auto; */
  /* grid-column-gap: 60px; */
  display: flex;
  text-align: justify;

  @media (max-width: 650px) {
    /* grid-template-columns: minmax(300px, 500px); */
    /* grid-template-areas: "left" "right"; */
  }
`
const SectionContainer = styled.div`
    text-align: justify;
    margin-bottom: 40px;
    margin-left: 40px;
    min-width: 300px;
    max-width: 500px;
`;

const ContentText = styled.div`
  color: #6e6e6e;
`
const ContentTitle = styled.h3`
  color: #10bce0;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: bold;
`
const ContentSubTitle = styled.h4`
  font-weight: 100;
  font-size: 16px;
  color: #303030;
  text-transform: capitalize;
  font-weight: 500;
`

export default function SingleTeamMemberScreen(props) {
    const [teamMemberData, setTeamMemberData] = useState(null);
    const [loading, setLoading] = useState(true);
    const url = window.location.pathname.replace(/\/$/, '');

    useEffect(() => {
        async function fetchData() {
            try {
                const memberSlug = props?.match?.params?.slug;
                if (memberSlug) {
                    setTeamMemberData(await getPostJSONData('team_member', `false/${memberSlug}`));
                    setLoading(false);
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, [props]);

    if (loading) return <Loading />
    const { post_title, acf } = teamMemberData;
    const { shortPageDescription, profileDescription, } = acf;
    return (
        <>
            <TopBar {...props.contact} />
            <NavigationBar {...props.header} />
            <Container>
                <BreadCrumbs url={url} />
                <PageHeader post_title={post_title} shortPageDescription={shortPageDescription} />
                <ContentContainer>
                    <TeamMemberCard {...teamMemberData} />
                    <Content>
                        {profileDescription.map(({ acf_fc_layout, contentBlock, leftContentBlock, rightContentBlock }, i) => {
                            if (acf_fc_layout === 'singleContent') {
                                return (
                                    <SectionContainer key={`content-block-${i}`}>
                                        {contentBlock.title && <ContentTitle>{contentBlock.title}</ContentTitle>}
                                        {contentBlock.paragraphHeading && <ContentSubTitle>{contentBlock.paragraphHeading}</ContentSubTitle>}
                                        <ContentText dangerouslySetInnerHTML={{ __html: contentBlock.contentBlock }} />
                                    </SectionContainer>
                                )
                            } else if (acf_fc_layout === 'dualContent') {
                                return (
                                    <DualContent key={`dual-content-block-${i}`}>
                                        <SectionContainer area="left">
                                            {leftContentBlock.title && <ContentTitle>{leftContentBlock.title}</ContentTitle>}
                                            {leftContentBlock.paragraphHeading && <ContentSubTitle>{leftContentBlock.paragraphHeading}</ContentSubTitle>}
                                            <ContentText dangerouslySetInnerHTML={{ __html: leftContentBlock.contentBlock }} />
                                        </SectionContainer>
                                        <SectionContainer area="right">
                                            {rightContentBlock.title && <ContentTitle>{rightContentBlock.title}</ContentTitle>}
                                            {rightContentBlock.paragraphHeading && <ContentSubTitle>{rightContentBlock.paragraphHeading}</ContentSubTitle>}
                                            <ContentText dangerouslySetInnerHTML={{ __html: rightContentBlock.contentBlock }} />
                                        </SectionContainer>
                                    </DualContent>
                                )
                            }
                            return null;
                        })}
                    </Content>
                </ContentContainer>
            </Container>
            <Footer {...props.footer} />
        </>
    )
}