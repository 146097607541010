import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { iconMapper } from '../../utils/icon-mapper'

const Container = styled.div`
    /* display: grid; */
    /* justify-items: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 245px;
    min-width: 245px;
    height: 475px; 
    color: #363636;
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const Image = styled.img`
  height: 350px;
  width: 100%;
  position: relative;
`
const Name = styled.h3`
  text-align: center;
  padding: 16px 0 0 0;
  font-size: 20px;
  text-transform: uppercase;
  color: #303030;
  font-weight: 500;
`
const Position = styled.p`
  text-align: center;
  padding: 6px 0px;
  color: #10bce0;
  text-transform: capitalize;
`
const SocialsContainer = styled.div`
  padding: 4px;
  min-width: 50%;
  display: block;
  justify-content: space-between;
`
const Icon = styled(FontAwesomeIcon)``;

const CenterIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: -16px;
  left: 105px;
  background: #fff;
  padding: 8px;
  border-radius: 50%;
  color: #10bce0;
  font-size: 1.3rem;
`
const ImageContainer = styled.div`
    position: relative;
`;

const SocialLink = styled.a`
    color: #98999e;
    margin-right: 15px;
`;

const TeamMemberCard = ({post_name, acf}) => {
    const { jobTitle, socialLinks, profileImage } = acf;
    return (
        <Container>
            <StyledLink href={`/team-member/${post_name}`}>
                <ImageContainer>
                    <Image src={profileImage?.sizes?.large} alt={profileImage.alt} />
                    <CenterIcon icon={faUser} />
                </ImageContainer>
                <Name>{post_name}</Name>
                <Position>{jobTitle}</Position>
            </StyledLink>
            <SocialsContainer>
                {socialLinks && socialLinks.map(({ link, title, type }, i) =>
                    <SocialLink key={`social-media-link-${type}-${i}`} href={`${type === 'email' ? `mailto:${link}` : link}`} title={title} target="_blank" rel="noopener">
                        <Icon key={i} icon={iconMapper[type]} />
                    </SocialLink>
                )}
            </SocialsContainer>
        </Container>
    )
}
export default TeamMemberCard;